"use strict";

import Bowser from "bowser";
export { get_browser_info };
export { get_browser_name };

function get_browser_info() {
  var spec = get_system_spec();
  var os_version = spec.os.versionName || spec.os.version;
  var browser_info = spec.browser.name + " " + spec.browser.version + ", " + spec.os.name;

  if (os_version) {
    browser_info += " " + os_version;
  }

  return browser_info;
}

function get_browser_name() {
  var spec = get_system_spec();
  return spec.browser.name;
}

function get_system_spec() {
  var spec = Bowser.parse(window.navigator.userAgent);
  return spec;
}