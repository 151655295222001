"use strict";

export { set_dev_mode };

function set_dev_mode() {
  var _process, _process$env;

  if (typeof process !== "undefined" && ((_process = process) === null || _process === void 0 ? void 0 : (_process$env = _process.env) === null || _process$env === void 0 ? void 0 : _process$env.NODE_ENV) === "production") {
    return;
  }

  if (window.location.hostname !== "localhost") {
    return;
  }

  document.documentElement.classList.add("is-dev");
}