"use strict";

import assert from "@brillout/assert";
var DEPLOY_ID = "ZYWbRaOH";
export { get_deploy_id };

if (typeof window !== "undefined") {
  window.deployId = window.DEPLOY_ID = DEPLOY_ID;
}

function get_deploy_id() {
  // In order to ensure source mapping, we ensure that the placeholder
  // string got replaced with a same-sized ID string.
  assert(DEPLOY_ID.length === 8);
  return DEPLOY_ID;
}